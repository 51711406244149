<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF UNPAID LOANS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2 pt-6">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="category"
              class="mx-3 pt-5"
              :items="['All','Laptop','Employee','Personal', 'Agent',  'Business', 'Pension', 'Educational', 'Damayan', 'Other']"
              item-value="id"
              label="Category"
              required
              @change="get_loan_approved_items"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              v-model="mode_of_loan"
              class="mx-3 pt-5"
              :items="['Weekly', '15/30', 'Monthly']"
              item-value="id"
              label="Mode of Loan"
              required
              @change="get_loan_approved_items"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-data-table
        :headers="headers"
        :items="list_of_unpaid_loans"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="headline">Total:  {{total_amount}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline">Balance: {{total_balance}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline" v-if="can_print">
                        Print
                        <v-icon
                          class="mr-2"
                          color="warning"
                          @click="print_loan_approved_data()"
                        >
                            {{icons.mdiPrinter}}
                        </v-icon>
                        </span>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.id_no}}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.loans.date_accomplished }}
            </td>
            <td>
              {{ item.loans.mode_of_loan }}
            </td>
            <td>
              {{ item.loans.terms }}
            </td>
            <td>
              {{ formatPrice(item.loans.amount_due) }}
            </td>
            <td>
              {{ formatPrice(item.balance) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        mode_of_loan: '',
        category: 'All',
        list_of_unpaid_loans: [],
        total_amount: '',
        total_balance: '',
        can_print: false,
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'ID', value: 'id'},
          {text: 'Laf #', value: 'loans.laf_no'},
          {text: 'Name', value: 'name'},
          {text: 'Date Released', value: 'loans.date_accomplished'},
          {text: 'Mode', value: 'loans.mode_of_loan'},
          {text: 'Terms', value: 'loans.terms'},
          {text: 'Due', value: 'loans.amount_due'},
          {text: 'Balance', value: 'balance'},
        ],
      }
    },
    created() {
      this.get_loan_approved_items()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id','branch', 'branch_address', 'branch_contact_no']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.category = ''
        this.list_of_unpaid_loans = []
        this.total_amount = '0'
        this.total_balance = '0'
      }
    },
    methods: {
      ...mapActions('loans_data', ['unpaid_loans']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      get_loan_approved_items() {
        this.data_table_loading = true
        const data = new FormData()
        data.append('category', this.category);
        data.append('mode_of_loan', this.mode_of_loan);
        data.append('branch_id', this.branch_id);
        this.unpaid_loans(data)
          .then((response) => {
            this.can_print = true
            this.list_of_unpaid_loans = response.data[0].loans
            this.total_amount = this.formatPrice(response.data[0].total_amount)
            this.total_balance = this.formatPrice(response.data[0].total_balance)
            this.data_table_loading = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      print_loan_approved_data() {
        var imgData = this.company_logo
        var payments_array = []

        payments_array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'Loan App. No', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Date Released', alignment: 'center', style: 'label'},
            {text: 'Mode', alignment: 'center', style: 'label'},
            {text: 'Terms', alignment: 'center', style: 'label'},
            {text: 'Due', alignment: 'center', style: 'label'},
            {text: 'Balance', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_unpaid_loans.length > 0) {
          var total_total = 0
          var total_balance = 0
          var total_due = 0
          this.list_of_unpaid_loans.forEach(function (item, index, loan) {
            total_balance = total_balance + parseFloat(item.balance)
            total_total = total_total + parseFloat(item.loans.total)
            total_due = total_due + parseFloat(item.loans.amount_due)
            payments_array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.id_no, alignment: 'left'},
                {text: item.name, alignment: 'left'},
                {text: item.loans.date_accomplished, alignment: 'center'},
                {text: item.loans.mode_of_loan, alignment: 'center'},
                {text: item.loans.terms, alignment: 'center'},
                {
                  text: (item.loans.amount_due / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'center'
                },
                {
                  text: (item.balance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'center'
                },
              ]
            )
            if (Object.is(loan.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total Due: ',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: (total_due / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total Balance: ',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: (total_balance / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total Loan: ',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: (total_total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF UNPAID LOANS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [30, 40, 130, 70, 40, 30, 60, 60],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
